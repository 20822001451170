import React, { Component } from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import Logo from '../components/logo'
import MenuIcon from '../components/menu'
import CloseIcon from '../components/close'

class Nav extends Component {
  constructor(props) {
    super(props)
    this.overlay = React.createRef()
  }

  handleClick = () => {
    this.overlay.current.classList.toggle('invisible')
  }

  render() {
    const children = this.props.children
    const overwrite = this.props.overwrite
    const logoFill = this.props.logoFill
    const logoHeight = this.props.logoHeight
    
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
            facebook: file(relativePath: {eq: "icon-facebook.svg"}) {
              extension
              publicURL
            }
            instagram: file(relativePath: {eq: "icon-instagram.svg"}) {
              extension
              publicURL
            }
          }
        `}
        render={data => (
          <>
            <nav className="nav-overlay container-fluid p-md-5 p-3 position-fixed h-100 invisible d-flex flex-column" ref={this.overlay}>
              <div className="row flex-nowrap align-items-center">
                <div className="col text-left"></div>
                <div className="text-center">
                  <Link className="fill-light" to="/" style={{height:"30px"}}><Logo /></Link>
                </div>
                <div className="col d-flex justify-content-end">
                  <button
                    onClick={this.handleClick}
                    className="btn btn-link border-0 p-0 target fill-light"
                    style={{height:"30px"}}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>

              <div className="row container m-auto">
                <div className="col d-flex flex-column nav-content">
                  <Link to="/">Home</Link>
                  <Link to="/explore">Inside Exchange 106</Link>
                  <Link to="/story">Stories</Link>
                  <Link to="/location">Location</Link>
                  <Link to="/gallery">Gallery</Link>
                  <Link to="/download">Downloads</Link>
                  <Link to="/contact">Contact</Link>
                </div>
              </div>

              <div className="row d-flex">
                <div className="col-7 col-md order-1 order-md-0 text-center">
                  <h6 className="text-uppercase">Contact</h6>
                  <ul className="list-unstyled">
                    <li><abbr className="small" title="Telephone">Tel</abbr> <a href="tel:+60327108806">+603 2710 8806</a></li>
                    <li><abbr className="small" title="Email">Email</abbr> <a href="mailto:info@exchange106.my">info@exchange106.my</a></li>
                    <li><abbr className="small" title="Website">Website</abbr> <a href="https://exchange106.my">exchange106.my</a></li>
                  </ul>
                </div>
                <div className="col-md order-0 order-md-1 text-center">
                  <h6 className="text-uppercase">Management Office</h6>
                  <p>Level 5, Exchange 106, Lingkaran TRX, 55188 Tun Razak Exchange, Kuala Lumpur</p>
                </div>
                <div className="col-5 col-md order-2 text-center">
                  <h6 className="text-uppercase">Social Media</h6>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a href="https://www.facebook.com/EXCHANGE106TRX" target="_blank" rel="noopener noreferrer">
                        <img src={data.facebook.publicURL} alt="Find us on Facebook" style={{height:"24px"}} />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/exchange106trx" target="_blank" rel="noopener noreferrer">
                      <img src={data.instagram.publicURL} alt="We are on Instagram" style={{height:"24px"}} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            <nav className="container-fluid p-md-5 p-3 position-fixed" style={{zIndex: 1}}>
              <div className="row flex-nowrap align-items-center">
                {children}
                <div className={`${overwrite}`}>
                  <Link className={`target fill-${logoFill}`} to="/" style={{height:logoHeight}}><Logo /></Link>
                </div>
                <div className="col d-flex justify-content-end">
                  <button
                    onClick={this.handleClick}
                    className={`btn btn-link border-0 p-0 target fill-${logoFill}`}
                    style={{height:"30px"}}
                  >
                    <MenuIcon />
                  </button>
                </div>
              </div>
            </nav>
          </>
        )}
      />
    )
  }
}

Nav.defaultProps = {
  overwrite: 'text-center',
  logoFill: 'dark',
  logoHeight: '30px'
}

export default Nav